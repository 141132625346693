// init
set_search();

window.addEventListener("load", function () {
    set_tracking();
    new PanelManager();
});

document.addEventListener("DOMContentLoaded", () => {
    set_stickiness();
});

function set_tracking() {
    document.querySelector(".page-layout").addEventListener("click", function (e) {
        let card = event.target.closest("fieldset");
        if (card) {
            navigator.sendBeacon(
                "/events",
                JSON.stringify([
                    {
                        type: "search-results__selection",
                        datetime_captured: new Date().toISOString(),
                        path: window.location.pathname,
                        time: performance.now(),
                        data: {
                            source: "card",
                            index: card.dataset.index,
                            id: card.dataset.id,
                            listing: card.dataset.listing,
                        },
                    },
                ]),
            );
        }
    });
}

function set_stickiness() {
    // init
    job();

    window.addEventListener("resize", job);

    function job() {
        set_property(
            "rm-search-top",
            element_height("header") + element_height(".top-bar"),
        );
        set_property("header-height", element_height("header"));
        set_property("top-bar-height", element_height(".top-bar"));
    }

    function set_property(prop, value) {
        document.documentElement.style.setProperty(`--${prop}`, `${value}px`);
    }

    function element_height(selector) {
        const element = document.querySelector(selector);
        return element.getBoundingClientRect().height;
    }
}

function set_search() {
    const MOBILE_BREAKPOINT = 1000;
    const parts = [
        {
            wrappers: {
                desktop: document.querySelector("#rm-search__desktop"),
                mobile: document.querySelector("#rm-search__mobile"),
            },
            template: document.querySelector("template#rm-search"),
        },
        {
            wrappers: {
                desktop: document.querySelector("#rm-search-filters__desktop"),
                mobile: document.querySelector("#rm-search-filters__mobile"),
            },
            template: document.querySelector("template#rm-search-filters"),
        },
    ];
    
    let cur_is_mobile = is_mobile();
    for (const part of parts) {
        part.wrappers[is_mobile() ? "mobile" : "desktop"].appendChild(
            part.template.content.cloneNode(true),
        );
    }

    window.addEventListener("resize", function (e) {
        const new_is_mobile = is_mobile();
        if (cur_is_mobile != new_is_mobile) {
            let [from, to] = cur_is_mobile
                ? ["mobile", "desktop"]
                : ["desktop", "mobile"];

            for (const part of parts) {
                let node = part.wrappers[from].firstElementChild;
                if (node) {
                    part.wrappers[from].firstElementChild.remove();
                    part.wrappers[to].appendChild(node);
                }
            }
            if (from == "mobile") {
                document.querySelector(".overlay").classList.remove("visible");
            }

            cur_is_mobile = new_is_mobile;
        }
    });

    function is_mobile() {
        return window.innerWidth <= MOBILE_BREAKPOINT;
    }
}


class PanelManager {
    constructor() {
        this.rightPanel = document.querySelector(".right-panel");
        this.topPanel = document.querySelector(".top-panel");
        this.filterBtn = document.querySelector("#toggle-filters-button");
        this.searchBtn = document.querySelector(
            '[aria-controls="rm-search__mobile"]',
        );
        this.applyBtn = document.querySelector("#apply-filters-button");
        this.overlay = document.querySelector(".overlay");
        this.mainContent = document.querySelector("body");

        this.init();
    }

    init() {
        this.filterBtn.addEventListener("click", () => this.togglerightPanel());
        this.applyBtn.addEventListener("click", () => this.togglerightPanel());
        this.searchBtn.addEventListener("click", () => this.toggleTopPanel());
        this.overlay.addEventListener("click", () => this.closeAllPanels());
    }

    togglerightPanel() {
        if (this.topPanel.classList.contains("open")) {
            this.topPanel.classList.remove("open");
        }
        this.rightPanel.classList.toggle("open");

        this.toggleOverlay();
    }

    toggleTopPanel() {
        if (this.rightPanel.classList.contains("open")) {
            this.rightPanel.classList.remove("open");
        }
        this.topPanel.classList.toggle("open");
        this.toggleOverlay();
    }

    closeAllPanels() {
        this.rightPanel.classList.remove("open");
        this.topPanel.classList.remove("open");
        this.overlay.classList.remove("visible");
        this.mainContent.classList.remove("noscroll");
        this.filterBtn.textContent = "Filters";
    }

    toggleOverlay() {
        const isPanelOpen =
            this.rightPanel.classList.contains("open") ||
            this.topPanel.classList.contains("open");

        this.overlay.classList.toggle("visible", isPanelOpen);
        this.mainContent.classList.toggle("noscroll", isPanelOpen);
    }
}
